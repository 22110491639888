<template>
  <input
    :class="this.class"
    :style="this.style"
    :id="this.id"
    min=0
    :placeholder="this.placeholder"
    :onkeypress="(event) => this.validarInput(event)"
    :value="this.getValor()"
    @input="(event) => this.limitarDecimais(event)"
    @blur="this._handleBlur"
    @focus="this._focus"
    :disabled="this.disabled"
    style="text-align: right;"
  />
</template>
<script>

export default {
  props: {
    pDisabled: {
      type: Boolean,
      default: false
    },
    pRequired: {
      type: Boolean,
      default: false
    },
    pPlaceholder: {
      type: String,
      default: ''
    },
    pMaxlength: {
      type: Number,
      default: 12
    },
    pId: {
      type: String,
      default: 'iptValor'
    },
    pClass: {
      type: String,
      default: 'input'
    },
    pStyle: {
      type: String,
      default: ''
    },
    pValue: Number,
    pQtdDecimais: {
      type: Number,
      default: 2
    },
    pExibePrefixo: Boolean
  },
  data () {
    return {
      disabled: this.pDisabled,
      required: this.pRequired,
      placeholder: this.pPlaceholder,
      maxlength: this.pMaxlength,
      id: this.pId,
      class: this.pClass,
      style: this.pStyle,
      value: this.pValue,
      qtdDecimais: this.pQtdDecimais,
      exibePrefixo: this.pExibePrefixo
    }
  },

  methods: {

    _handleBlur (event) {
      const input = document.getElementById(this.id)
      this.setValor(input)
    },

    _focus (event) {
      const ipt = event.target
      ipt.value = ipt.value.replace('R$', '').replaceAll('.', '').trim()
    },

    emitirEventoAlteracao () {
      document.dispatchEvent(
        new CustomEvent('input-valor-monetario-alterado', {
          detail: this.value,
          composed: true
        })
      )
    },

    /**
     * Método para validar o valor
     * @method
     * @param {Object} field - Objeto que representa o componente do valor de lançamento
     * @returns {Boolean} - Valor de lançamento válido ou não
     */
    validarValor (field) {
      let data = field.value

      if (data.startsWith('R$')) {
        data = data.substring(2, data.length)
      }

      data = data.replace('.', '').replace(',', '')
      data = parseFloat(data)

      if (isNaN(data)) {
        field.value = null
      }

      return !!field.value
    },

    /**
     * Método para setar o valor
     * @method
     * @param {Object} event - Objeto que representa o evento de blur do componente do valor
     */
    setValor (input) {
      if (!this.validarValor(input)) {
        this.value = null
        return
      }

      let value = input.value
      if (!value.includes(',')) {
        if (value.length === this.maxlength) {
          value = value.substring(0, value.length - this.qtdDecimais) + ',' + value.substring(value.length - this.qtdDecimais, value.length)
        } else {
          value = value + ',00'
        }
      }

      let valorFormatado = value.replace(',', '.').replace('R$', '')
      valorFormatado = this.removerTodosExcetoUltimo(valorFormatado, '.')

      // if (parseFloat(this.value).toFixed(this.qtdDecimais) === parseFloat(valorFormatado).toFixed(this.qtdDecimais)) {
      //   input.value = this.getValor()
      // } else {
      this.value = parseFloat(valorFormatado).toFixed(this.qtdDecimais)
      // }
    },

    removerTodosExcetoUltimo (string, token) {
      var parts = string.split(token)
      return parts.length === 1 ? string : parts.slice(0, -1).join('') + token + parts.slice(-1)
    },

    /**
     * Método que retorna o valor formatado em pt-br
     * @method
     * @returns {String} - Valor formatado em pt-br
     */
    getValor () {
      if (!this.value) return ''

      // let formatConfig = {}

      // if (this.exibePrefixo) {
      //   formatConfig = {
      //     style: 'currency',
      //     currency: 'BRL',
      //     minimumFractionDigits: 0,
      //     currencyDisplay: 'symbol'
      //   }
      // } else {
      //   formatConfig = {
      //     currency: 'BRL',
      //     minimumFractionDigits: 0,
      //     currencyDisplay: 'symbol'
      //   }
      // }

      let value = this.value.toString()
      if (!value.includes('.') && !value.includes(',')) {
        if (value.length === this.maxlength) {
          value = value.substring(0, value.length - this.qtdDecimais) + ',' + value.substring(value.length - this.qtdDecimais, value.length)
        } else {
          value = value + ',00'
        }
      }

      let valorFormatado = value.replace(',', '.').replace('R$', '')
      valorFormatado = this.removerTodosExcetoUltimo(valorFormatado, '.')

      valorFormatado = valorFormatado.toString().replace('.', ',') // new Intl.NumberFormat('pt-br', formatConfig).format(this.value.toString().replace(',', '.'))
      // valorFormatado = new Intl.NumberFormat('pt-br', formatConfig).format(this.value.toString().replace(',', '.'))
      // valorFormatado = valorFormatado.substring(0, valorFormatado.length - this.qtdDecimais).replace(',', '') + ',' + valorFormatado.substring(valorFormatado.length - this.qtdDecimais, valorFormatado.length)
      return valorFormatado
    },

    /**
     * Método que limita a quantidade de decimais no input do valor
     * @method
     * @param {Object} event - Objeto que representa o evento de input do componente de valor
     */
    limitarDecimais (event) {
      const value = event.target.value
      event.target.value = value.indexOf(',') >= 0 ? value.substr(0, value.indexOf(',')) + value.substr(value.indexOf(','), this.qtdDecimais + 1) : value
    },

    validarInput (event) {
      const charCode = event.which ? event.which : event.keyCode
      const value = event.target.value
      const possuiSeparador = value.includes('.') || value.includes(',')

      if ((charCode === 46 || charCode === 44) && !possuiSeparador) {
        const indexStart = event.target.selectionStart
        const indexEnd = event.target.selectionEnd
        event.target.value = value.substr(0, indexStart) + ',' + value.substr(indexEnd, value.length - 1)
        event.target.setSelectionRange(indexStart + 1, indexStart + 1)
        return false
      }

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false
      }

      const valorDigitado = value.replace('.', '').replace(',', '')

      if (valorDigitado.length === this.maxlength) {
        return false
      }

      return true
    },

    checkValidity () {
      if (this.required) {
        return !this.value
      }

      return true
    },

    reset () {
      this.value = null
    }
  },
  watch: {
    pValue: {
      handler: function (val, old) {
        this.value = this.pValue
      },
      deep: true
    },
    pExibePrefixo: {
      handler: function (val, old) {
        this.exibePrefixo = this.pExibePrefixo
      },
      deep: true
    }
  }
}
</script>
