<template>
  <div id="listagemCotacoes">
        <DataTableBasico
          ref="cotacoes"
          :customHighlighters="customHighlighters"
          :pStatus="this.status"
          :pDataSource="this.dataSourceCotacao"
          :pData="this.cotacoes"
          :p-search="false"
          :pHideButtonMore="false"
          :paginacao="true"
          :p-quantidade-de-items="page.total"
          :modal-delecao-visivel="() => false"
          :idDivUtilizadaPaginacao="'listagemCotacoes'"
          div-style="height: 76vh; margin-bottom: -5rem"
          :noIcon="true"
          :next-page="carregar"
          :p-show-buttons="false"
          delete-button-caption=""
          edit-button-caption=""
          :desativarAtalhos="this.desativarAtalhosDataTable"
        >
          <template v-slot:new-item>
            <Button class="button is-primary" data-group="btn-novo-cotacao" :title="shortkeys.MSG_KEY_NOVO" @click="cadastrar">Novo</Button>
          </template>
          <template #btns="{ item }">
            <div class="buttons is-right">
              <button
                datagroup="editar-cotacao"
                class="button is-small is-pulled-right"
                type="button"
                @click="editar(item)"
              >
                <span class="icon"><i class="fas fa-edit"></i></span>
              </button>
              <button
                datagroup="excluir-cotacao"
                class="button is-small is-pulled-right"
                type="button"
                @click="prepararExclusao(item)"
              >
                <span class="icon"><i class="fas fa-trash"></i></span>
              </button>
            </div>
          </template>
        </DataTableBasico>
  </div>
  <cotacao-edicao
    v-if="this.cadastrandoOuEditando"
    :item="this.item"
    :novoCadastro="this.novoCadastro"
    :idIndexador="this.idIndexador"
    @cancel="this.cadastrandoOuEditando = false"
    @save="this.atualizar"
  />
  <ModalMessage
    ref="focoModalExclusao"
    datagroup="-modal-excluir-cotacao"
    ok-caption="Excluir cotação"
    cancel-type="primary"
    :message="mensagem"
    :active="showModalExclusao"
    @close="aoFechar"
  />
</template>

<script>
import { indexadorCotacaoService as service } from './service/indexador-cotacao-service.js'
import IndexadorCotacao from './model/indexador-cotacao.js'
import CotacaoEdicao from './IndexadorCotacaoEdicao.vue'
import { Page } from '@/models/page.js'
import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { formatDate, formatMoney } from '@packonline/packonline-reutilizaveis-frontend/Utils/intl'
import { toast } from 'bulma-toast'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao'
import Button from '@alterdata/component-vue/Button/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'

export default {
  components: {
    CotacaoEdicao,
    DataTableBasico,
    Button,
    ModalMessage
  },
  props: {
    idIndexador: {
      type: String,
      default: ''
    },
    fecharQuickview: {
      type: Function,
      default: () => {}
    }
  },
  mixins: [mixin, mixinModalSaida, mixinFocoPrimeiroElementoDataTable],
  emits: ['fechar-cadastro'],
  inject: ['setActiveSection', 'addShortcut'],
  data () {
    return {
      cotacoes: [],
      cadastrandoOuEditando: false,
      novoCadastro: false,
      item: new IndexadorCotacao(),
      page: new Page(null),
      status: '',
      hideButtonMore: false,
      paginacao: false,
      shortkeys: shortkeys,
      showModalExclusao: false,
      preDelete: undefined,
      pagina: 0,
      dataSourceCotacao: {
        columns: [
          {
            name: 'data',
            label: 'Data',
            style: 'width:25%; text-align: center',
            styleItems: 'text-align: center',
            format: this._formatarDataNoGrid
          },
          {
            name: 'valor',
            label: 'Valor',
            format: this._formatarColunaValor,
            style: 'width:40%; text-align: right',
            styleItems: 'text-align: right'
          },
          {
            name: 'btns',
            slot: true
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(),
        select: (item) => this.editar(item),
        delete: (item) => this.prepararExclusao(item),
        loadMore: () => this.loadMore()
      },
      linkAjuda: 'https://ajuda.alterdata.com.br/display/POD/Cadastrar+Indexadores',
      desativarAtalhosDataTable: false
    }
  },
  watch: {
    idIndexador: {
      handler: function (val, old) {
        this.carregar()
      },
      deep: true
    },
    cadastrandoOuEditando (val) {
      this.desativarAtalhosDataTable = val
      !val && this.focarNoPrimeiroElementoDataTable('cotacoes')
    },
    showModalExclusao (val) {
      this.desativarAtalhosDataTable = val
      !val && this.focarNoPrimeiroElementoDataTable('cotacoes')
    }
  },
  computed: {
    mensagem () {
      if (this.preDelete) {
        return `Deseja excluir a cotação de "${formatDate(this.preDelete.data)}" valor "${formatMoney(this.preDelete.valor).replace('R$', '').trim()}"?`
      }
      return ''
    }
  },
  methods: {
    async carregar (pagina = 0) {
      this.pagina = pagina
      this.status = 'is-loading'
      try {
        const result = await service.get(
            `/api/v1/cotacao-indexadores?idIndexador=${this.idIndexador}&page=${this.pagina}`
        )
        this.page = new Page(result)
        if (this.page.total === 0) {
          this.status = ''
          this.cotacoes = []
        } else {
          this.cotacoes = this.page.content
          for (const element of this.cotacoes) {
            element.data = element.data.substring(0, 10)
            element.id =
                element.codigo +
                '-' +
                element.data.replaceAll('-', '')
          }
          this._ordenarGridPorData()
          this.status = ''
        }
      } catch {
        this.status = 'is-danger'
      }
    },
    prepararExclusao (item) {
      this.preDelete = item
      this.showModalExclusao = true
      this.definirAtalhoModalExclusao()
      this.$nextTick(() => {
        this.selecionarCancelarOuGravarModal()
        this.$refs.focoModalExclusao.buttonFocus({ type: 'cancel' })
      })
    },
    aoFechar (event) {
      if (event === 'ok') {
        this.excluirCotacao(this.preDelete)
        this.confirmarExclusao()
      } else {
        this.showModalExclusao = false
        this.setActiveSection('listagemCotacoes')
      }
    },
    async excluirCotacao (cotacao) {
      service
        .delete('/api/v1/cotacao-indexadores', cotacao)
        .then((response) => {
          if (response.ok) {
            this.carregar()
            this._ordenarGridPorData()
          }
        })
    },
    cadastrar () {
      this.item = new IndexadorCotacao()
      this.item.codigo = this.idIndexador
      this.novoCadastro = true
      this.abrirFecharEdicao()
    },

    editar (cotacao) {
      Object.assign(this.item, cotacao)
      this.novoCadastro = false
      this.abrirFecharEdicao()
    },

    abrirFecharEdicao () {
      this.cadastrandoOuEditando = !this.cadastrandoOuEditando
    },

    atualizar () {
      this.carregar()
      this.abrirFecharEdicao()
    },
    _ordenarGridPorData () {
      this.cotacoes.sort(function (a, b) {
        if (a.data === b.data) {
          return 0
        } else {
          return a.data > b.data ? 1 : -1
        }
      })
    },
    _formatarDataNoGrid (data) {
      data = new Date(data)
      data = data.setHours(data.getHours() + 3)
      return new Intl.DateTimeFormat('pt-br').format(new Date(data))
    },
    _formatarColunaValor (valor) {
      return valor.toLocaleString('pt-br', { minimumFractionDigits: 6 })
    },
    fecharCadastro () {
      this.fecharQuickview()
    },

    definirAtalhoListagemCotacoes () {
      const section = 'listagemCotacoes'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, () => {
        this.fecharCadastro()
      })
      this.addShortcut(section, shortkeys.KEY_NOVO, this.cadastrar)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    definirAtalhoModalExclusao () {
      const section = 'modalExclusaoCotacoes'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, () => { this.fecharModal() })
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    fecharModal () {
      this.showModalExclusao = false
      this.setActiveSection('listagemCotacoes')
    },
    confirmarExclusao () {
      toast({
        message: 'Excluímos!',
        type: 'is-success',
        dismissible: true,
        pauseOnHover: true
      })
      this.showModalExclusao = false
    },

    _direcionaParaAjuda () {
      window.open(this.linkAjuda, '_blank').focus()
    }

  },

  mounted () {
    this.carregar()
    this.definirAtalhoListagemCotacoes()
  },

  unmounted () {
    this.setActiveSection('listagemIndexadores')
  }
}
</script>
