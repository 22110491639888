import { Service } from '../../../service.js'

class IndexadorCotacaoService extends Service {
  constructor () {
    super()
    this.teste = true
  }

  async save (url, dto, novoCadastro) {
    if (novoCadastro) {
      return this.post(url, dto)
    }
    return this.patch(url, dto)
  }
}

export const indexadorCotacaoService = new IndexadorCotacaoService()
