<template>
    <Modal width="840px" :title="this.novoCad ? 'Cotação (Cadastro)' : 'Cotação (Edição)'" @close="this._sairSemSalvar()" :active="true" id="cadastroEdicaoCotacao">
          <div id='divCadastro' class="columns is-multiline">
            <div class="field column is-5">
              <div class="control">
                <input-data
                  id="dataEmissao"
                  class="input"
                  label="Data"
                  :disabled="!this.novoCad"
                  @update:erro="this.erros.data = $event"
                  @update:model-value="
                    this.projecaoCotacao.cotacao.data = $event
                  "
                  :model-value="this.projecaoCotacao.cotacao.data"
                  :status="this.erros.data ? 'error' : ''"
                  :message="erros.data"
                  required
                />
              </div>
            </div>
            <div class="field column is-5">
              <label class="label" for="nome" style="margin-bottom: 0 !important;">Valor</label>
              <div class="control">
                <input-valor-monetario
                  :pId="'iptValor'"
                  :pClass="'input'"
                  :class="{ 'is-danger': erros.valor }"
                  :pValue="this.projecaoCotacao.cotacao.valor"
                  :pRequired="true"
                  :pPlaceholder="'Valor da cotação'"
                  :pMaxlength="12"
                  :pQtdDecimais="6"
                  :pExibePrefixo="false"
                  @blur="(event) => this.setValor(event.target.value)"
                  @keypress="somenteNumeros($event)"
                />
              </div>
              <p class="help is-danger">{{ erros.valor }}</p>
            </div>
            <div v-if="this.novoCad" class="field column is-4">
              <div
                class="field-label is-normal"
                style="text-align: left; white-space: nowrap"
              >
                <label class="label" for="nome">
                  <input
                    type="checkbox"
                    id="realizarProjecao"
                    class="checkbox"
                    v-model="this.projecaoCotacao.realizarProjecao"
                  />
                  Realizar projeção até
                </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <input-data
                    :key="atualizaDataFinalProjecao"
                    id="dataEmissao"
                    :disabled="!this.projecaoCotacao.realizarProjecao"
                    @update:erro="this.erros.dataFinalProjecao = $event"
                    @update:model-value="
                      this.projecaoCotacao.dataFinalProjecao = $event
                    "
                    :model-value="this.projecaoCotacao.dataFinalProjecao"
                    :status="this.erros.dataFinalProjecao ? 'error' : ''"
                    :message="this.erros.dataFinalProjecao"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="this.projecaoCotacao.realizarProjecao"
              class="field column is-5"
              style="margin-top: 5px;"
            >
              <label
                class="label"
                for="tipoProjecao"
                style="margin-bottom: unset"
                >Tipo de projeção</label
              >
              <label class="radio" style="padding-right: 2.5rem">
                <input
                  type="radio"
                  :class="{ 'is-danger': erros.tipoProjecao }"
                  name="tipoProjecao"
                  value="D"
                  v-model="this.projecaoCotacao.tipoProjecao"
                />
                Diária
              </label>
              <label class="radio" style="padding-right: 2.5rem">
                <input
                  type="radio"
                  :class="{ 'is-danger': erros.tipoProjecao }"
                  name="tipoProjecao"
                  value="M"
                  v-model="this.projecaoCotacao.tipoProjecao"
                />
                Mensal
              </label>
              <p class="help is-danger">{{ erros.tipoProjecao }}</p>
            </div>
            <div
              v-if="
                this.projecaoCotacao.realizarProjecao &&
                this.projecaoCotacao.tipoProjecao === 'M'
              "
              class="column is-12"
              style="padding-bottom: 0rem; margin: 0"
            >
              <div>
                <label class="label" for="tipoProjecao">Projeção mensal</label>
                <div>
                  <label class="radio" style="padding-right: 2.5rem">
                    <input
                      type="radio"
                      name="rsvp"
                      value="P"
                      v-model="this.projecaoCotacao.tipoProjecaoMensal"
                    />
                    Pelo primeiro dia de cada mês
                  </label>
                  <label class="radio" style="padding-right: 2.5rem; padding-bottom: 0;">
                    <input
                      type="radio"
                      name="rsvp"
                      value="U"
                      v-model="this.projecaoCotacao.tipoProjecaoMensal"
                    />
                    Pelo último dia de cada mês
                  </label>
                  <label class="radio" style="width: 25%; margin: 0">
                    <div class="is-flex is-align-items-flex-end">
                      <input
                        type="radio"
                        name="rsvp"
                        value="D"
                        style="margin-bottom: 4px;"
                        v-model="this.projecaoCotacao.tipoProjecaoMensal"
                      />
                      <span class="ml-1">Pelo dia</span>
                      <div style="width: 20%" class="is-fullwidth mr-1 ml-1">
                        <input-texto
                          v-if="this.projecaoCotacao.tipoProjecaoMensal === 'D'"
                          id="dia"
                          style="height: 25px; margin-bottom: -6px;"
                          maxlength="2"
                          v-model="this.projecaoCotacao.diaDoMesProjecaoMensal"
                          :status="
                            this.getStatus(this.erros.diaDoMesProjecaoMensal)
                          "
                          @update:erro="
                            this.erros.diaDoMesProjecaoMensal = $event.message
                          "
                          @keypress="somenteNumeros($event)"
                        />
                        <input-texto
                          v-else
                          id="dia"
                          maxlength="2"
                          style="height: 25px; margin-bottom: -6px"
                          disabled
                        />
                      </div>
                      <span>de cada mês</span>
                    </div>
                    <p
                      class="help"
                      :class="{
                        'is-info':
                          this.getStatus(this.erros.diaDoMesProjecaoMensal) ===
                          'info',
                        'is-danger':
                          this.getStatus(this.erros.diaDoMesProjecaoMensal) ===
                          'error'
                      }"
                    >
                      {{ erros.diaDoMesProjecaoMensal }}
                    </p>
                  </label>
                </div>
                <p class="help is-danger">{{ erros.tipoProjecaoMensal }}</p>
              </div>
            </div>
          </div>
          <template v-slot:footer>
            <ButtonAjuda :linkAjuda="this.linkAjuda" :title="shortkeys.MSG_KEY_AJUDA" style="margin-right: auto" />
            <Button class="button is-primary" @click="this._salvarCotacao" :title="shortkeys.MSG_KEY_GRAVAR" caption="Gravar" style="margin-right: 1rem"/>
            <Button class="button" @click="this.cancelar()" :title="shortkeys.MSG_KEY_ESC" caption="Cancelar"/>
          </template>
      </Modal>
</template>

<script>
import { indexadorCotacaoService as service } from './service/indexador-cotacao-service.js'
import Cotacao from './model/indexador-cotacao.js'
import InputValorMonetario from '@/components/InputValorMonetario/index.vue'
import { toast } from 'bulma-toast'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils//mixin.js'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'
import Button from '@alterdata/component-vue/Button/index.vue'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import Modal from '@alterdata/component-vue/Modal/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao.js'

const ERROS = {
  data: '',
  valor: '',
  dataFinalProjecao: '',
  tipoProjecao: '',
  tipoProjecaoMensal: '',
  diaDoMesProjecaoMensal: ''
}

export default {
  components: {
    'input-valor-monetario': InputValorMonetario,
    'input-data': InputDate,
    'input-texto': InputText,
    Button,
    ButtonAjuda,
    Modal
  },
  props: {
    item: Cotacao,
    novoCadastro: Boolean
  },

  mixins: [mixin, mixinModalSaida, mixinRouterGuardian],
  beforeRouteLeave (to, from, next) {
    this.verificarModificaoNoItem()
    this.beforeRoute(to, from, next)
  },
  inject: ['setActiveSection', 'addShortcut'],
  data () {
    return {
      novoCad: this.novoCadastro,
      erros: ERROS,
      projecaoCotacao: {
        realizarProjecao: false,
        dataFinalProjecao: null,
        tipoProjecao: null,
        tipoProjecaoMensal: null,
        diaDoMesProjecaoMensal: null,
        cotacao: this.item
      },
      projecaoCotacaoOriginal: null,
      atualizaDataFinalProjecao: 0,
      shortkeys: shortkeys,
      linkAjuda: 'https://ajuda.alterdata.com.br/display/POD/Cadastrar+Indexadores'
    }
  },

  computed: {
    modalSaida () {
      return this.$root.mostrarModalDadosAlterados
    },
    dadosAlteradosRoot () {
      return this.$root.dadosAlterados
    },
    verificandoDadosAlteradosRoot () {
      return this.$root.verificandoDadosAlterados
    }
  },

  mounted () {
    this.projecaoCotacaoOriginal = utils.cloneDeep(this.projecaoCotacao)
    this._definirAtalhosCadastroCotacao()
    this.dadosInalterados()
    this.focarNoPrimeiroInput('divCadastro')
    const { parametrosModalSalvarMudancas } = this.$root
    parametrosModalSalvarMudancas.eventoSalvar = this._salvarCotacao
    parametrosModalSalvarMudancas.eventoSair = this._sairSemSalvar
  },

  unmounted () {
    this.setActiveSection('listagemCotacoes')
  },

  methods: {
    async _salvarCotacao () {
      try {
        var dataOld = this.projecaoCotacao.cotacao.data
        Cotacao.validar(this.projecaoCotacao.cotacao, this.projecaoCotacao)
        this.projecaoCotacao.cotacao.data = this._formataData(
          this.projecaoCotacao.cotacao.data
        )
        this.projecaoCotacao.dataFinalProjecao = this._formataData(
          this.projecaoCotacao.dataFinalProjecao
        )

        if (!this.projecaoCotacao.realizarProjecao) {
          service
            .save(
              '/api/v1/cotacao-indexadores',
              this.projecaoCotacao.cotacao,
              this.novoCad
            )
            .then(this.responseToSave)
        } else {
          service
            .save(
              '/api/v1/projecao-cotacao-indexadores',
              this.projecaoCotacao,
              this.novoCad
            )
            .then(this.responseToSave)
        }
        this.dadosInalterados()
      } catch (e) {
        Object.assign(this.erros, JSON.parse(e.message))
      } finally {
        this.projecaoCotacao.cotacao.data = dataOld
      }
    },

    async responseToSave (response) {
      const json = await response.json()

      if (response.ok) {
        this.$emit('save', json)
      } else if (response.status === 422) {
        this.erros = json
      } else if (response.status === 409) {
        var listaErros = []
        listaErros = json.message.replace('[', '').replace(']', '').split(',')

        for (var i = 0; i < listaErros.length; i++) {
          toast({
            message: listaErros[i],
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true
          })
        }

        if (this.projecaoCotacao.realizarProjecao) {
          this.$emit('save', json)
        }

        Object.assign(this.erros, { codigo: json.message })
      }
    },

    _formataData (data) {
      data = new Date(data)
      data = data.toISOString()
      return data
    },

    cancelar () {
      this.verificarModificaoNoItem()
      if (this.dadosAlteradosRoot) {
        this.$root.mostrarModalDadosAlterados = true
      } else {
        this._sairSemSalvar()
      }
    },
    setValor (valor) {
      this.projecaoCotacao.cotacao.valor = valor.toString().replace(',', '.')
    },

    somenteNumeros (evt) {
      utils.onlyNumber(evt, true)
    },

    _sairSemSalvar () {
      this.erros.data = ''
      this.erros.valor = ''
      this.dadosInalterados()
      this.$emit('cancel')
    },

    verificarModificaoNoItem () {
      const cotacao = JSON.parse(JSON.stringify(utils.cloneDeep(this.projecaoCotacao)))
      this.formatarCotacao(cotacao)
      const cotacaoOriginal = JSON.parse(JSON.stringify(utils.cloneDeep(this.projecaoCotacaoOriginal)))
      this.formatarCotacao(cotacaoOriginal)
      const existeModificacao = !utils.isEqual(cotacao, cotacaoOriginal)
      if (existeModificacao) {
        this.$root.dadosAlterados = true
      } else {
        this.$root.dadosAlterados = false
      }
    },
    formatarCotacao (cotacao) {
      let valor = cotacao?.cotacao?.valor.toString()
      if (!valor.includes('.')) {
        valor += '.00'
        cotacao.cotacao.valor = valor
      }
    },

    _definirAtalhosCadastroCotacao () {
      const section = 'cadastroCotacao'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_GRAVAR, this._salvarCotacao)
      this.addShortcut(section, shortkeys.KEY_ESC, () => this.cancelar())
      this.addShortcut(section, shortkeys.KEY_AJUDA, this._direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this._direcionaParaAjuda)
    },

    _direcionaParaAjuda () {
      window.open(this.linkAjuda, '_blank').focus()
    }
  },
  watch: {
    projecaoCotacao: {
      handler: function (val, old) {
        try {
          if (val.cotacao.data) {
            this.erros.data = ''
          }

          if (val.cotacao.valor) {
            this.erros.valor = ''
            Cotacao.validarValor(val.cotacao.valor)
          }

          if (val.dataFinalProjecao) {
            this.erros.dataFinalProjecao = ''
          }

          if (val.tipoProjecao) {
            this.erros.tipoProjecao = ''
            this.atualizaKeyProjecaoCotacaoDiaDoMesProjecaoMensal++
          }

          if (val.tipoProjecaoMensal) {
            this.erros.tipoProjecaoMensal = ''
          }

          if (val.diaDoMesProjecaoMensal) {
            this.erros.diaDoMesProjecaoMensal = ''
          }
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    'projecaoCotacao.realizarProjecao' () {
      if (this.projecaoCotacao.realizarProjecao) {
        this.atualizaDataFinalProjecao++
      }
    },
    modalSaida (val) {
      if (!val) {
        this.setActiveSection('cadastroCotacao')
      }
    },
    verificandoDadosAlteradosRoot: {
      handler (val) {
        if (val) {
          this.verificarModificaoNoItem()
        }
      }
    }
  }
}
</script>
<style>
#danger-diaDoMesProjecaoMensal {
  margin: 0% 0% 0% 73%;
}

.alt-modal-card-title {
  margin-bottom: 0 !important;
}

</style>
