export default class IndexadorCotacao {
  constructor () {
    this.id = ''
    this.codigo = ''
    this.data = ''
    this.valor = ''
  }

  static validar ({ data, valor }, projecaoCotacao) {
    const erros = {}
    if (!data) {
      erros.data = 'Informe a data'
    } else if (new Date(data) < new Date('1900-01-01')) {
      erros.data = 'Data inválida'
    }

    if (!valor) {
      erros.valor = 'Informe o valor'
    }

    if (valor) {
      this.validarValor(valor)
    }

    if (projecaoCotacao.realizarProjecao && !projecaoCotacao.dataFinalProjecao) {
      erros.dataFinalProjecao = 'Informe a data final da projeção'
    } else if (projecaoCotacao.realizarProjecao && new Date(projecaoCotacao.dataFinalProjecao) <= new Date(data)) {
      erros.dataFinalProjecao = 'Data final da projeção deve ser maior que a data da cotação'
    } else if (new Date(projecaoCotacao.dataFinalProjecao) < new Date('1900-01-01')) {
      erros.dataFinalProjecao = 'Data inválida'
    }

    if (projecaoCotacao.realizarProjecao && !projecaoCotacao.tipoProjecao) {
      erros.tipoProjecao = 'Selecione o tipo'
    }

    if (projecaoCotacao.realizarProjecao && projecaoCotacao.tipoProjecao === 'M' && !projecaoCotacao.tipoProjecaoMensal) {
      erros.tipoProjecaoMensal = 'Selecione o tipo de projeção mensal'
    }

    if (projecaoCotacao.realizarProjecao && projecaoCotacao.tipoProjecao === 'M' && projecaoCotacao.tipoProjecaoMensal === 'D' && !projecaoCotacao.diaDoMesProjecaoMensal) {
      erros.diaDoMesProjecaoMensal = 'Informe o dia'
    }

    if (projecaoCotacao.realizarProjecao && projecaoCotacao.tipoProjecao === 'M' && projecaoCotacao.tipoProjecaoMensal === 'D' && projecaoCotacao.diaDoMesProjecaoMensal > 31) {
      erros.diaDoMesProjecaoMensal = 'Dia do mês inválido'
    }

    if (Object.keys(erros).length > 0) {
      throw new Error(JSON.stringify(erros))
    }
  }

  static validarValor (valor) {
    const erros = {}

    if (valor < 0) {
      erros.valor = 'Valor inválido'
    }

    if (Object.keys(erros).length > 0) {
      throw new Error(JSON.stringify(erros))
    }
  }
}
